import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';

import { hydrate, render } from "react-dom";

import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'

import {currentStage, appVersion} from './Helper';


/*ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);*/


Bugsnag.start({
  apiKey: 'd7bebdd277a0fcda543d89c362507a31',
  appVersion: appVersion(),
  autoTrackSessions: false,
  collectUserIp: false,
  generateAnonymousId: false,
  releaseStage: currentStage(),
  enabledReleaseStages: ['production', 'staging'],
  plugins: [new BugsnagPluginReact()]
})
const rootElement = document.getElementById('root');
const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React)
const wrappedApp = <ErrorBoundary><App /></ErrorBoundary>
if (rootElement.hasChildNodes()) {
    hydrate(wrappedApp, rootElement);
} else {
    render(wrappedApp, rootElement);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
