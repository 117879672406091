import React from 'react';

class Waiting extends React.Component {
    render() {
        return (
            <div style={{height: "100%", width: "100%"}}>
                <h2>
                    Kaiserstühler Tesla-Vermietung
                </h2>
                <h4>
                    Seite wird geladen.
                </h4>
            </div>
        );
    }
}

export default Waiting;
