import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import React, { Suspense } from 'react';
import Waiting from './pages/Waiting';



const Main = React.lazy(() => import('./Main'));
const Start = React.lazy(() => import('./pages/Start'));
const Experience = React.lazy(() => import('./pages/Experience'));
const NotFound = React.lazy(() => import('./pages/NotFound'));
const Price = React.lazy(() => import('./pages/Price'));
const Faq = React.lazy(() => import('./pages/Faq'));
const Contact = React.lazy(() => import('./pages/Contact'));

const Impressum = React.lazy(() => import('./pages/Impressum'));

function App() {
  return (
    <Router>
      <Suspense fallback={<Waiting />}>
        <Routes>
          <Route path="/" element={<Main page={<Start />}/>} />
          <Route path="/erlebnis" element={<Main page={<Experience />}/>} />
          <Route path="/preise" element={<Main page={<Price />}/>} />
          <Route path="/faq" element={<Main page={<Faq />}/>} />
          <Route path="/kontakt" element={<Main page={<Contact />}/>} />
          <Route path="/impressum" element={<Main page={<Impressum />}/>} />
        </Routes>
      </Suspense>
    </Router>
  )
}

export default App;
