import packageJson from './../package.json';

export function imgUrlForPath(path) {
    const suffix = window.Modernizr.webp ? ".webp" : ".jpg";
    return path + suffix
}

export function completeTitle(title) {
    const suffix = "Kaiserstühler Tesla-Vermietung";
    return title ? title + " - " + suffix : suffix
}

export function currentStage() {
    return process.env.REACT_APP_STAGE;
}

export function isProduction() {
    return currentStage() === 'production'
}

export function appVersion() {
    return packageJson.version;
}